
.localizedText .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedText textarea {
  border-top:0px;
}
.localizedText.has-success .nav-tabs {
  border-color: #1ab394;
}
.localizedText.has-error .nav-tabs {
  border-color: #ed5565;
}
.localizedText.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedText.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}


:root {
  --ck-z-default: 2200
}
.localizedContent .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedContent.has-success .nav-tabs, 
.localizedContent.has-success .ck.ck-toolbar {
  border-color: #1ab394;
}
.localizedContent.has-error .nav-tabs,
.localizedContent.has-error .ck.ck-toolbar {
  border-color: #ed5565;
}
.localizedContent.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedContent.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}
.localizedContent .ck.ck-toolbar {
  border-top:0px;
}
.localizedContent.has-success .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.localizedContent.has-success .ck.ck-editor__main>.ck-source-editing-area>textarea {
  border-color: var(--ck-color-base-border) #1ab394 #1ab394 ;
}
.localizedContent.has-error .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.localizedContent.has-error .ck.ck-editor__main>.ck-source-editing-area>textarea {
  border-color: var(--ck-color-base-border) #ed5565 #ed5565 ;
}
.localizedContent .ck.ck-editor__main>.ck-editor__editable,
.localizedContent .ck.ck-editor__main>.ck-source-editing-area {
  min-height:200px;
}
.localizedContent .wordCount { 
  text-align: right;
  padding: 5px;
  border: 1px solid var(--ck-color-toolbar-border);
  border-top: 0px;
  background-color: var(--ck-color-toolbar-background);
}



.tox-tinymce-aux {
  z-index:2200 !important;
}




.fa-check.active[data-v-ccf4ac24], .fa-user-circle-o.active[data-v-ccf4ac24] {
  color:green;
}
.fa-check.disabled[data-v-ccf4ac24], .fa-user-circle-o.disabled[data-v-ccf4ac24] {
  color:red;
}
.component-updated[data-v-ccf4ac24] {
  color:orange;
}
.component-removed[data-v-ccf4ac24] {
  color:red;
}
.component-new[data-v-ccf4ac24] {
  color:green;
}




.routes .admin {
  border: 1px solid #dee2e6;
  padding: 20px;
}
.routes .admin textarea {
    min-height: 300px;
}
.box.sk-loading {
  position: relative;
}
.box.sk-loading:after {
  content: '';
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.box.sk-loading > .sk-spinner {
  display: block;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 2000;
}



form[name=site-switch-form] {
  display: flex;
}
form[name=site-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=site-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=site-switch-form] > div > div {
  flex-grow:1;
}
form[name=site-switch-form] > button {
  margin-left:10px;
}

